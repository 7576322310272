import { Template } from 'views/WorkflowsAndActions/Workflows/templateTypes';

const workflowUrls = {
  addWorkflowAssetButtonPress: () => '/api/flow/workflows/asset-button-press',
  addWorkflowAssetEnterExit: () => '/api/flow/workflows/asset-enter-exit-location',
  addWorkflowMotherInfantMismatch: () => '/api/flow/workflows/mother-infant-mismatch',
  addWorkflowPatientButtonCall: () => '/api/flow/workflows/patient-button-call',
  addWorkflowPatientEnterExit: () => '/api/flow/workflows/patient-enter-exit-location',
  addWorkflowPatientOutOfSafeZone: () => '/api/flow/workflows/patient-out-of-safe-zone',
  addWorkflowPatientTagNoSignal: () => '/api/flow/workflows/patient-tag-no-signal',
  addWorkflowPatientTagRemoved: () => '/api/flow/workflows/patient-tag-removed',
  addWorkflowStaffButtonCall: () => '/api/flow/workflows/staff-button-call',
  addWorkflowStaffEnterExit: () => '/api/flow/workflows/staff-enter-exit-location',
  deleteWorkflow: (workflowId: string) => `/api/flow/workflows/${workflowId}`,
  duplicateWorkflow: (workflowId: string) => `/api/flow/workflows/${workflowId}/duplicate`,
  getAssetButtonPressDetail: (workflowId: string) =>
    `/api/flow/workflows/asset-button-press/${workflowId}`,
  getAssetEnterExitDetail: (workflowId: string) =>
    `/api/flow/workflows/asset-enter-exit-location/${workflowId}`,
  getMotherInfantMismatchDetail: (workflowId: string) =>
    `/api/flow/workflows/mother-infant-mismatch/${workflowId}`,
  getPatientButtonCallDetail: (workflowId: string) =>
    `/api/flow/workflows/patient-button-call/${workflowId}`,
  getPatientEnterExitDetail: (workflowId: string) =>
    `/api/flow/workflows/patient-enter-exit-location/${workflowId}`,
  getPatientOutOfSafeZoneDetail: (workflowId: string) =>
    `/api/flow/workflows/patient-out-of-safe-zone/${workflowId}`,
  getPatientTagNoSignalDetail: (workflowId: string) =>
    `/api/flow/workflows/patient-tag-no-signal/${workflowId}`,
  getPatientTagRemovedDetail: (workflowId: string) =>
    `/api/flow/workflows/patient-tag-removed/${workflowId}`,
  getStaffButtonCallDetail: (workflowId: string) =>
    `/api/flow/workflows/staff-button-call/${workflowId}`,
  getStaffEnterExitDetail: (workflowId: string) =>
    `/api/flow/workflows/staff-enter-exit-location/${workflowId}`,
  getWorkflowActions: (workflowId: string) => `/api/flow/workflows/${workflowId}/actions/details`,
  searchWorkflows: () => '/api/flow/workflows/search',
  updateWorkflowActions: (workflowId: string) => `/api/flow/workflows/${workflowId}/actions`,
  updateWorkflowAssetButtonPress: (workflowId: string) =>
    `/api/flow/workflows/asset-button-press/${workflowId}`,
  updateWorkflowAssetEnterExit: (workflowId: string) =>
    `/api/flow/workflows/asset-enter-exit-location/${workflowId}`,
  updateWorkflowMotherInfantMismatch: (workflowId: string) =>
    `/api/flow/workflows/mother-infant-mismatch/${workflowId}`,
  updateWorkflowPatientButtonCall: (workflowId: string) =>
    `/api/flow/workflows/patient-button-call/${workflowId}`,
  updateWorkflowPatientEnterExit: (workflowId: string) =>
    `/api/flow/workflows/patient-enter-exit-location/${workflowId}`,
  updateWorkflowPatientOutOfSafeZone: (workflowId: string) =>
    `/api/flow/workflows/patient-out-of-safe-zone/${workflowId}`,
  updateWorkflowPatientTagNoSignal: (workflowId: string) =>
    `/api/flow/workflows/patient-tag-no-signal/${workflowId}`,
  updateWorkflowPatientTagRemoved: (workflowId: string) =>
    `/api/flow/workflows/patient-tag-removed/${workflowId}`,
  updateWorkflowStaffButtonCall: (workflowId: string) =>
    `/api/flow/workflows/staff-button-call/${workflowId}`,
  updateWorkflowStaffEnterExit: (workflowId: string) =>
    `/api/flow/workflows/staff-enter-exit-location/${workflowId}`,
  updateWorkflowStatusToActive: (workflowId: string) =>
    `/api/flow/workflows/${workflowId}/activate`,
  updateWorkflowStatusToPassive: (workflowId: string) =>
    `/api/flow/workflows/${workflowId}/deactivate`
};

const actionsUrls = {
  createChangeAttributeAction: () => '/api/flow/actions/change-attribute',
  createNotificationAction: () => '/api/flow/actions/notification',
  createRaiseAlertAction: () => '/api/flow/actions/raise-alert',
  createTriggerApiAction: () => '/api/flow/actions/trigger-api',
  createTriggerDeviceAction: () => '/api/flow/actions/trigger-device',
  deleteAction: (actionId: string) => `/api/flow/actions/${actionId}`,
  disassociateWorkflowsByAction: (actionId: string) =>
    `/api/flow/actions/${actionId}/disassociate-workflows`,
  duplicateAction: (actionId: string) => `/api/flow/actions/${actionId}/duplicate`,
  getActionVariables: (template: Template) => `/api/flow/actions/variables?template=${template}`,
  getActionsAssociations: (actionId: string) => `/api/flow/actions/${actionId}/associations`,
  getChangeAttributeDetail: (actionId: string) => `/api/flow/actions/change-attribute/${actionId}`,
  getRaiseAlertDetail: (actionId: string) => `/api/flow/actions/raise-alert/${actionId}`,
  getSendNotificationDetail: (actionId: string) => `/api/flow/actions/notification/${actionId}`,
  getTriggerApiDetail: (actionId: string) => `/api/flow/actions/trigger-api/${actionId}`,
  getTriggerDeviceDetail: (actionId: string) => `/api/flow/actions/trigger-device/${actionId}`,
  searchActionDetails: () => '/api/flow/actions/search/details',
  searchActions: () => '/api/flow/actions/search',
  testTriggerApi: () => '/api/flow/actions/trigger-api/test',
  updateActionStatusToActive: (actionId: string) => `/api/flow/actions/${actionId}/activate`,
  updateActionStatusToPassive: (actionId: string) => `/api/flow/actions/${actionId}/deactivate`,
  updateChangeAttributeAction: (changeAttributeActionId: string) =>
    `/api/flow/actions/change-attribute/${changeAttributeActionId}`,
  updateNotificationAction: (notificationId: string) =>
    `/api/flow/actions/notification/${notificationId}`,
  updateRaiseAlertAction: (actionId: string) => `/api/flow/actions/raise-alert/${actionId}`,
  updateTriggerApiAction: (actionId: string) => `/api/flow/actions/trigger-api/${actionId}`,
  updateTriggerDeviceAction: (actionId: string) => `/api/flow/actions/trigger-device/${actionId}`
};

const eventRecordingUrls = {
  downloadVideoRecording: () => `/api/flow/event-recordings/download`,
  getEventRecordingByIdQuery: (eventRecordingId: string) =>
    `/api/flow/event-recordings/${eventRecordingId}`
};

const socketUrls = {
  connectRealTimeVideoSocket: (eventRecordingId: string) =>
    `/socket/flow/hubs/real-time-video?eventRecordingId=${eventRecordingId}`
};

const alertUrls = {
  getAlertById: (alertId: string) => `/api/flow/alerts/${alertId}`,
  searchAlertLogsById: (alertId: string) => `/api/flow/alerts/${alertId}/logs/search`,
  searchAlerts: () => '/api/flow/alerts/search'
};

export default {
  ...workflowUrls,
  ...actionsUrls,
  ...socketUrls,
  ...eventRecordingUrls,
  ...alertUrls
};
