import { AlertPanelFilter } from './types';

export const defaultAlertPanelFilter: AlertPanelFilter = {
  branches: [],
  extraFilters: [],
  priorities: [],
  products: [],
  sortBy: 'creationDateByNewest',
  statuses: ['open', 'inProgress']
};

export const clearableFieldsKeys: Array<keyof AlertPanelFilter> = [
  'branches',
  'extraFilters',
  'priorities',
  'products',
  'sortBy'
];
